import React, { useEffect, useState } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { getAmplifyConfig } from '../utils/auth'
import { getConfig } from '../common/config'

const config = getConfig()
const amplifyConfig = getAmplifyConfig()

Amplify.configure(amplifyConfig)

const IndexPage = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user)
      })
      .catch((e) => {
        console.log('Not logged in')
      })

    setLoaded(true)
  }, [])

  if (!loaded) {
    return null
  }

  return <div>
    {currentUser && <div><h1>Authenticated!</h1><a href={config.retoolUrl}>Proceed to Retool</a></div>}
    {!currentUser && <button onClick={() => Auth.federatedSignIn({ provider: 'Google' })}>Login with SSO</button>}
  </div>
}

export default IndexPage
