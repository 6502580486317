import {
  getConfig, getServiceUrlForEnv
} from '../common/config'

export const getAmplifyConfig = () => {
  const config = getConfig()
  const { serviceEnv } = config

  const authUrl = new URL(getServiceUrlForEnv('auth', serviceEnv))
  const adminUrl = new URL(config.adminUrl)

  console.log(config)

  return {
    Auth: {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.cognitoClientId,
      mandatorySignIn: true,
      redirectSignIn: config.adminUrl,
      redirectSignOut: config.adminUrl,
      cookieStorage: {
        domain: adminUrl.host,
        secure: true,
        path: '/',
        expires: 365
      },

      oauth: {
        domain: authUrl.host,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: config.adminUrl,
        redirectSignOut: config.adminUrl,
        responseType: 'code'
      }
    }
  }
}
